import React from 'react';
import './index.css';

export const App: React.FC = () => (<article className="logo">
  <a href="/">
    <span className="logo-dot pulse">.</span>
    <span className="logo-sign">initiative</span>
  </a>
  <h1>создание сайтов и их продвижение</h1>
  <a href="mailto:dream@punktinitiative.ru" className="email">[dream@punktinitiative.ru]</a>
</article>);
